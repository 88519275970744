import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { UsuarioLogin } from './interfaces/usuario-login';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  usuario$ = new BehaviorSubject<UsuarioLogin>(null);

  constructor() { }

  setUsuarioSubject(usuario: UsuarioLogin) {
    this.usuario$.next(usuario);
  }

  getUsuarioSubject() {
    return this.usuario$.asObservable();
  }

  /**
     * 
     * @param title 
     * @param text 
     * @returns 
     */
  async fireSwalAsync(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncThreeButtons(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncWarning(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
    }
    )

    return result.isConfirmed;
  }

  generateUUIDV4(): string {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // eslint-disable-next-line no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }  
}
